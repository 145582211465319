<template>
<div class="bg-degradado-green pt-3 container-tarjeta-app ">
    <Navbar path="/"/>
    <p class="title-2 text-center">Tarjeta</p>

    <section class="flex-container offset">
        <article class="flex-item justify-align-center">
            <img class="img-small" src="../assets/animacion/14/Yipi_AnimacionWeb_14.gif"/>
        </article>
        <article >
            <ul class="flex-item"> 
                <li class="flex-container"> 
                    <p class="circle-blue justify-align-center text-circle">01</p>
                    <p class="description-circle"> Acude a nuestro punto de afiliación y recarga más cercano.</p>
                </li>
                <li class="flex-container"> 
                  <p class="circle-blue justify-align-center text-circle">02</p>
                   <p class="description-circle">Proporciona los siguientes datos: nombre, fecha de nacimiento, teléfono y/o correo.</p>
                </li>
                <li class="flex-container"> 
                   <p class="circle-blue justify-align-center text-circle">03</p>
                   <p class="description-circle">Te llegará un SMS de confirmación que nos deberás proporcionar para tu alta como usuario.</p>
                </li>
                <li class="flex-container"> 
                    <p class="circle-blue justify-align-center text-circle">04</p>
                   <p class="description-circle">Recarga saldo a tu tarjeta.</p>
                </li>
                <li class="flex-container"> 
                    <p class="circle-blue justify-align-center text-circle"> 05</p>
                    <p class="description-circle">¡Listo a viajar!</p>
                </li>
            </ul>
        </article>
    </section>

</div>
</template>
<script>
import Navbar from "../components/commons/Navbar.vue";
export default {
    components:{
        Navbar
    }
}
</script>
<style >
    @import '../styles/tarjeta.css';
    @import '../styles/quienesSomos.css';
</style>