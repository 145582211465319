<template>
  <div class="navbar">
    <router-link :to="path ? path :''" >
    <img class="img-logo" src="../../assets/320x480/LogoYipi_Verde.png" 
     />
       </router-link>
  </div>
</template>

<script>
export default {
  name: "nav-bar",
  props: ['icon', 'path']
};
</script>
<style >
.img-logo {
  width: auto;
  height: 45px;
  margin-left: 50px;
  margin-top: 10px;
  cursor: pointer;
  object-fit: cover;
}
.navbar {
  background-color: var(--gray-light);
  height: 80px;
  width: 100%;

}

@media (max-width: 600px) {
  .navbar {
    background-color: var(--gray-light);
    height: 65px;
    width: 100%;
  }
  .img-logo {
    width: 100px;
    height: 35px;
      object-fit: contain;
    margin-left: 50px;
  }
}
</style>